export const MAX_ALLOWED_VALUE_OF_TRANSACTION_ORDERING_INDEX = 999; //don't go higher, it reaches max number limit on frontend

export const PAGE_SIZE = 15;
export const BRAID_ALERTS_MAX_ROWS_IN_PAGE = 10;

export const MAX_ROWS_IN_TABLE = 1000;

export const WIRE_STATUS_PENDING_SECOND_APPROVAL = 'Pending Second Approval';
export const WIRE_STATUS_PENDING_FI = 'Pending FI';

export const MAX_ALLOWED_AMOUNT_FOR_RDC_PAYEE_THAT_IS_NOT_FULL_PROFILE = 3000;

export const HAWK_AI_FOR_WIRE = 'hawk-ai-for-wire';
export const HAWK_AI_FOR_RDC = 'hawk-ai-for-rdc';
export const HAWK_AI_FOR_WIRE_JOINED = 'hawk-ai-for-wire-joined';
export const HAWK_AI_FOR_RDC_JOINED = 'hawk-ai-for-rdc-joined';
export const HAWK_AI_FOR_WIRE_LEFT = 'hawk-ai-for-rdc-left';
export const HAWK_AI_FOR_RDC_LEFT = 'hawk-ai-for-rdc-left';