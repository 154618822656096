export const environment = {
  production: true,
  domain: '/api',
  websocket: '',
  recaptcha: {
    enabled: true,
    siteKey: '6LfgvTgqAAAAALNd4twLFWenu6pRszrZfw06J6ni'
  },
  mockScanner: false,
  debugAllowed: false,
  bankName: 'IONFI',
  bankIconPath: '/assets/favIcons/ionfi-favicon.ico',
  bankLogoPath: 'assets/logos/logo-ionfi.svg'
};
