import {FormControl, FormGroup} from '@angular/forms';
import {mexicoStates} from '@shared/assets/data/states/mexico.states';
import {usStates} from '@shared/assets/data/states/us.states';
import {environment} from '@env/environment';
import moment from 'moment-timezone';
import {MAX_ROWS_IN_TABLE} from '@shared/utils/constants';
import {QuestionDefinition} from '@shared/models/question-definition';
import {Account} from "@shared/models/account";
import {BraidCustomerWithoutAccount} from '@shared/models/braid_customer_without_account';

export const tablesLimit = MAX_ROWS_IN_TABLE;
export const defaultDebounceTime = 200;
export const formChangeDebounceTime = 1000;

export function trimTimestampFromFilename(filename: string): string {
  return filename.slice(filename.indexOf('-') + 1);
}

export function download(fileName: string, blob: Blob) {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('visibility', 'hidden');
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Returns Date from input string with format yyyy-MM-dd, with hour and minutes set to 0
 */
export function getDateFromInput(dateString: string) {
  if (typeof (dateString) !== 'string') {
    throw new Error('invalid call of the getDateFromInput function: dateString input is not string');
  }
  const result = new Date(dateString);
  result.setTime(result.getTime() + result.getTimezoneOffset() * 60 * 1000);
  return result;
}

export function checkIsFiltered(formGroup: FormGroup) {
  return Object.keys(formGroup.value).some(k => !!formGroup.value[k]
    && (
      (!Array.isArray(formGroup.value[k])) || formGroup.value[k].filter(it => it).length > 0)
    );
}

export function addDays(date: Date, numDays: number): Date {
  date.setTime(date.getTime() + numDays * 24 * 60 * 60 * 1000);
  return date;
}

export function newLinesToBrTags(text: string) {
  if (!text) {
    return '';
  }
  return text.replace(new RegExp('\n', 'g'), '<br />');
}

export function getSafeToDisplay(text: string) {
  return text ? text : '';
}

export function MatchPasswordValidator(AC: FormControl) {
  const dataForm = AC.parent;
  if (!dataForm) {
    return null;
  }

  const verify = dataForm.get('verify');
  const passwordValue = dataForm.get('password').value;
  const verifyValue = verify.value;

  if (passwordValue !== verifyValue) {
    if (verify === AC) {
      return {MatchPassword: true};
    }
    dataForm.controls['verify'].setErrors({MatchPassword: true});
  } else {
    dataForm.controls['verify'].setErrors(null);
  }
  return null;
}

export function getStates(countryCode: string) {
  return countryCode === 'USA' ? usStates : countryCode === 'MEX' ? mexicoStates : [];
}

export function shortenText(text: string, length: number): string {
  if (text.length <= length) {
    return text;
  }
  return text.substr(0, length) + '...';
}

export function getLogoPath(): string {
  return environment.bankLogoPath;
}

export function toArray(enumm) {
  return Object.keys(enumm).map(key => enumm[key]);
}

export function regexFilter(value: string): RegExp {
  return new RegExp(value.trim().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), "i");
}

export function createFragment(formGroupFilter: any): string {
  const result = [];
  for (const key in formGroupFilter) {
    let value = formGroupFilter[key];
    if (value && (value instanceof String)) {
      value = value.trim();
    }
    if (value === "") {
      value = null;
    }
    if (value instanceof Date) {
      value = moment(value).format("YYYY-MM-DD");
    }
    if (value && Array.isArray(value)) {
      value = value.join(',');
    }
    if (value || isNaN(Number(value))) {
      result.push(`${key}=${value}`);
    }
  }
  return result.join('&');
}

export function getAccessToken(): string | null {
  return localStorage.session ? JSON.parse(localStorage.session).accessToken : null;
}

export function getRefreshToken(): string | null {
  return localStorage.session ? JSON.parse(localStorage.session).refreshToken : null;
}

export function isQuestionnaireUBOAllowedToSyncWithBraid(block: QuestionDefinition) {
  const ownerTypeQuestion = block.sub_questions.find(it => it.title === 'Owner Type');
  if (!ownerTypeQuestion) {
    return false;
  }
  const textAnswer = ownerTypeQuestion.answer.text_answer;
  if (!textAnswer || textAnswer && !textAnswer.length) {
    return false;
  }
  return textAnswer[0] === 'Individual';
}

export function getBraidDashboardLinkForEntity(dashboardDomain: string, entityType: string, entityId: string | number) {
  let link = dashboardDomain + '/';
  switch (entityType) {
    case 'OFAC':
      link += 'compliance/ofac';
      break;
    default:
      return '';
  }
  link += `/${entityId}`;
  return link;
}

export function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}
