<shared-loading-bar *ngIf="(questionsState && questionsState.readingQuestionsAnswered | async) || (downloadingFile | async)"></shared-loading-bar>
<div *ngIf="questionsAnsweredOrdered | async as qa">
  <div *ngFor="let question of qa" class="mb-5">
    <div *ngFor="let block of question.blocks; let i = index" class="answer-group-block">
      <div class="answer-row group-title-row" *ngIf="question.blocks.length > 1 && i == 0">
        <span class="question-block text-muted" [ngClass]="{'title-size': block.type_of_question === 'title'}">
            {{ block.question }}
          </span>
        <shared-edit-question-answer-modal
          [answeredQuestion]="question"
          (answerQuestionEventEmitter)="updateAnswer($event, question.id)"></shared-edit-question-answer-modal>
      </div>
      <div class="answer-row title-row" *ngIf="currProfile | async as profile">
          <span class="question-block text-muted" [ngClass]="{'title-size': block.type_of_question === 'title'}">
            {{ block.question }}
            <span *ngIf="question.blocks.length > 1">({{ i + 1 }})</span>
          </span>
          <span class="answer-block float-right text-14">
            <shared-answer-view [questionsState]="questionsState"
                                [kycProfile]="kycProfile" [block]="block" [question]="block" (downloadPart)="downloadFile($event)"></shared-answer-view>
          </span>
          <span *ngIf="question.blocks.length == 1">
            <shared-edit-question-answer-modal
              [answeredQuestion]="question"
              (answerQuestionEventEmitter)="updateAnswer($event, question.id)"></shared-edit-question-answer-modal>
          </span>
        <ng-container *ngFor="let acc of kycProfile.segregateAccounts">
          <div *ngIf="profile.user.isAdminFI && acc.braid_fi_business_id && isQuestionAllowedForBraid(block, acc)" class="float-right">
            <div>
              @if(isOutOfSyncWithBraid(block, acc)) {
                <button class="btn-no-transition bg-braid-shadow btn-sm ld-ext-right mr-1"
                        (click)="syncUBOWithBraid(block, acc)"
                        [ngClass]="{'running': syncingUBOWithBraidShowSpinner() == acc.id}"
                        [disabled]="syncingUBOWithBraid()">
                  <span class="text-14">{{'shared.questions-answered: Send latest changes to Braid' | translate}}</span>
                  <div class="ld ld-ring ld-spin"></div>
                </button>
              }
              @if (!isUploadedToBraid(block, acc)) {
                <button class="btn-no-transition bg-braid-shadow btn-sm ld-ext-right mr-1"
                        (click)="syncUBOWithBraid(block, acc)"
                        [ngClass]="{'running': syncingUBOWithBraidShowSpinner() == acc.id}"
                        [disabled]="syncingUBOWithBraid()">
                  <span class="text-14">{{'shared.questions-answered: Send as UBO to Braid FI Business' | translate}}</span>
                  <div class="ld ld-ring ld-spin"></div>
                </button>
              }
              @if (isUploadedToBraid(block, acc)) {
                <a [href]="acc.upstreamBank.braid_dashboard_link+'/individuals/'+getBraidIndividualId(block, acc)" target="_blank" class="text-color-braid-ubo">
                  <fa-icon [classes]="['fa-fw']" class='icon-space margin-auto' icon="arrow-up-right-from-square"></fa-icon>
                  {{ 'shared.questions-answered: Braid UBO' | translate }} {{acc.upstreamBank.name}}
                </a>
                <br>
              }
            </div>
          </div>
        </ng-container>
      </div>
      <div class="answer-row value-row" *ngFor="let subQuestion of block.sub_questions">
        <span class="question-block text-muted"
              [ngClass]="{'title-size': subQuestion.type_of_question === 'title'}">{{ subQuestion.title }}</span>
        <span class="answer-block float-right text-14">
            <shared-answer-view [questionsState]="questionsState"
                                [kycProfile]="kycProfile" [block]="block" [question]="subQuestion" (downloadPart)="downloadFile($event)"></shared-answer-view>
          </span>
      </div>
      <div *ngIf=" (currProfile | async)?.user?.isAdminFI" class="mt-3 mb-5">
        <questionnaire-changes-modal [answerLogs]="block.answerLogs"></questionnaire-changes-modal>
      </div>
    </div>
  </div>
</div>
