import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {AdminApiService} from '@app/admin-module/services/admin.api.service';
import {combineLatest, debounceTime, finalize, first, map, switchMap} from 'rxjs/operators';
import {defaultDebounceTime} from '@app/shared-module/utils/utils';
import {FormArray, FormGroup} from '@angular/forms';
import {AssignedTemplate, Template} from '@shared/models/template';
import {AnsweredQuestion, QuestionDefinition} from '@shared/models/question-definition';
import {Profile} from '@shared/models/profile';
import {KycProfile} from '@shared/models/kyc-profile';
import {QuestionFormData} from '@app/shared-module/utils/question.utils';


@Injectable()
export class ProfileQuestionsStateProvider {

  constructor(private rxUtilsService: RxUtilsService,
              private adminApiService: AdminApiService) {

  }

  getProfileQuestionsState(profile: KycProfile): ProfileQuestionsState {
    return new ProfileQuestionsState(profile, this.rxUtilsService, this.adminApiService);
  }

}

export class ProfileQuestionsState {

  assignedTemplates: Observable<AssignedTemplate[]>;
  questionsPending: Observable<QuestionDefinition[]>;
  questionsAnswered: Observable<AnsweredQuestion[]>;

  readingQuestionsNew: Observable<boolean>;
  readingQuestionsPending: Observable<boolean>;
  readingQuestionsAnswered: Observable<boolean>;

  readQuestionsNew$ = new BehaviorSubject<boolean>(true);
  readQuestionsPending$ = new BehaviorSubject<boolean>(true);
  readQuestionsAnswered$ = new BehaviorSubject<boolean>(true);

  assigningTemplates$ = new BehaviorSubject<boolean>(false);
  editingQuestion$ = new BehaviorSubject<boolean>(false);
  deletingQuestion$ = new BehaviorSubject<boolean>(false);
  publishingTemplates$ = new BehaviorSubject<boolean>(false);

  assigningTemplates: Observable<boolean>;
  editingQuestion: Observable<boolean>;
  deletingQuestion: Observable<boolean>;
  publishingTemplates: Observable<boolean>;

  constructor(private profile: KycProfile,
              private rxUtilsService: RxUtilsService,
              private adminApiService: AdminApiService) {

    this.assignedTemplates = this.rxUtilsService.createGetStateValue(this.readQuestionsNew$,
      this.adminApiService.getAssignedTemplatesForProfile(profile),
      'Error while getting new questions', null);
    this.readingQuestionsNew = this.rxUtilsService.createReadingInfo(this.readQuestionsNew$, this.assignedTemplates)
      .pipe(debounceTime(defaultDebounceTime));

    this.questionsPending = this.rxUtilsService.createGetStateValue(this.readQuestionsPending$,
      this.adminApiService.getPendingQuestionsForProfile(profile),
      'Error while getting pending questions', null);
    this.readingQuestionsPending = this.rxUtilsService.createReadingInfo(this.readQuestionsPending$, this.questionsPending)
      .pipe(debounceTime(defaultDebounceTime));

    this.questionsAnswered = this.rxUtilsService.createGetStateValue(this.readQuestionsAnswered$,
      this.adminApiService.getAnsweredQuestionsForProfile(profile),
      'Error while getting answered questions', null);
    this.readingQuestionsAnswered = this.rxUtilsService.createReadingInfo(this.readQuestionsAnswered$, this.questionsAnswered)
      .pipe(debounceTime(defaultDebounceTime));

    this.assigningTemplates = this.assigningTemplates$.asObservable().pipe(debounceTime(defaultDebounceTime));
    this.editingQuestion = this.editingQuestion$.asObservable().pipe(debounceTime(defaultDebounceTime));
    this.deletingQuestion = this.deletingQuestion$.asObservable().pipe(debounceTime(defaultDebounceTime));
    this.publishingTemplates = this.publishingTemplates$.asObservable().pipe(debounceTime(defaultDebounceTime));
  }

  getAssignedTemplates(): Observable<AssignedTemplate[]> {
    return this.assignedTemplates;
  }

  getReadingQuestionsNew(): Observable<boolean> {
    return this.readingQuestionsNew;
  }

  getQuestionsPending(): Observable<QuestionDefinition[]> {
    return this.questionsPending;
  }

  getReadingQuestionsPending(): Observable<boolean> {
    return this.readingQuestionsPending;
  }

  getQuestionsAnswered(): Observable<AnsweredQuestion[]> {
    return this.questionsAnswered;
  }

  getReadingQuestionsAnswered(): Observable<boolean> {
    return this.readingQuestionsAnswered;
  }

  assignTemplates(profile: KycProfile, event: { templates: Observable<Template[]>, selectForm: Observable<FormGroup> }): Observable<void> {
    this.assigningTemplates$.next(true);
    return event.selectForm.pipe(
      first(),
      map((form) => (form.controls['selected'] as FormArray).controls.map(control => control.value)),
      combineLatest(event.templates, (selectedValues, templates) => {
        return templates.filter((value, index) => selectedValues[index]);
      }),
      first(),
      switchMap(selectedTemplates => this.adminApiService.assignTemplatesForProfile(profile, selectedTemplates)),
      finalize(() => {
        this.assigningTemplates$.next(false);
        this.reloadQuestionsNew();
      })
    );
  }

  getAssigningTemplates(): Observable<boolean> {
    return this.assigningTemplates;
  }

  editQuestion(profile: KycProfile, questionToEdit: QuestionDefinition, questionEdited: QuestionDefinition): Observable<void> {
    this.editingQuestion$.next(true);
    return this.adminApiService.editQuestionForProfile(profile, questionToEdit, questionEdited).pipe(
      finalize(() => {
        this.editingQuestion$.next(false);
        this.reloadQuestionsNew();
      })
    );
  }

  answerQuestion(profileId: number, questionId: string, questionFormData: QuestionFormData) {
    return this.adminApiService
      .answerQuestionForProfileWithProgress(profileId, questionId, questionFormData)
      .pipe(
        finalize(() => {
          this.reloadQuestionsAnswered();
          this.reloadQuestionsPending();
        })
      );
  }

    getEditingQuestion(): Observable<boolean> {
    return this.editingQuestion;
  }

  publishTemplates(profile: KycProfile, selectForm: Observable<FormGroup>, templates: AssignedTemplate[]): Observable<void> {
    this.publishingTemplates$.next(true);
    return selectForm.pipe(
      first(),
      map(form => {
        const selectedValues = form.value['selected'];
        return templates.filter((value, index) => selectedValues[index]);
      }),
      switchMap(selectedTemplates => this.adminApiService.publishTemplatesForProfile(profile, selectedTemplates)),
      finalize(() => {
        this.publishingTemplates$.next(false);
        this.reloadQuestionsNew();
        this.reloadQuestionsPending();
        this.reloadQuestionsAnswered();
      })
    );
  }

  getPublishingTemplates$(): Observable<boolean> {
    return this.publishingTemplates;
  }

  revokeTemplate(profile: KycProfile, template: AssignedTemplate): Observable<void> {
    this.deletingQuestion$.next(true);
    return this.adminApiService.revokeTemplateFromProfile(profile, template).pipe(
      finalize(() => {
        this.deletingQuestion$.next(false);
        this.reloadQuestionsNew();
      })
    );
  }

  answerConfirmationChange(event: { question: AnsweredQuestion, confirmed: boolean }, profile: Profile): Observable<void> {
    return this.adminApiService.answerConfirmationChangeForProfile(event, profile).pipe(
      finalize(() => this.reloadQuestionsAnswered())
    );
  }

  getDeletingQuestion(): Observable<boolean> {
    return this.deletingQuestion;
  }

  reloadQuestionsNew(): void {
    this.readQuestionsNew$.next(true);
  }

  reloadQuestionsPending(): void {
    this.readQuestionsPending$.next(true);
  }

  reloadQuestionsAnswered(): void {
    this.readQuestionsAnswered$.next(true);
  }

  uploadDocumentToBraid(kycProfileId: number, blockId: string, segregateAccountId: number, fileName: string, uploadToFIBusiness: boolean) {
    return this.adminApiService.uploadQuestionDocumentToBraid(kycProfileId, blockId, segregateAccountId, fileName, uploadToFIBusiness).pipe(
      finalize(() => this.reloadQuestionsAnswered())
    );
  }

  syncUBOWithBraid(segregateAccountId: number, blockId: string) {
    return this.adminApiService.syncUBOForLegalEntityWithBraid(segregateAccountId, blockId).pipe(
      finalize(() => this.reloadQuestionsAnswered())
    );
  }

  updateAnswer(profileId: number, questionId: string, formData: QuestionFormData) {
    return this.adminApiService.updateAnswerQuestionForProfile(profileId, questionId, formData).pipe(
        finalize(() => {
          this.reloadQuestionsAnswered();
          this.reloadQuestionsPending();
        })
      );
  }
}
